import React from 'react';
import * as Icon from 'react-feather';
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby"

// Shape Images
import Shape2 from "../assets/images/shape2.svg";
import Shape4 from "../assets/images/shape4.svg";
import Rectangle from "../assets/images/rectangle.png"

const query = graphql`
{   
    allStrapiProjects {
        nodes {
            id
            title
            image {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            shortText
            longDescription
            slug
        }
    }
}
`

const ProjectStyle1 = () => {

    const data = useStaticQuery(query)
    const {
        allStrapiProjects: {nodes: projects}
    } = data

    return (
        <div className="works-area pt-80 pb-50 bg-f7fafd" id={"products"}>
            <div className="container">
              <div className="container">
                <div className="section-title">
                  <h2>Services & Products</h2>
                  <div className="bar"/>
                </div>
              </div>

                <div className="row justify-content-center">
                    {projects.map(project => (
                        <div className="col-lg-4 col-md-6 col-sm-12" key={project.id}>
                            <div className="single-services-box-item">
                              <div className="icon">
                                <img src={project.image.childImageSharp.fluid.src} alt="Project"
                                  />
                              </div>
                              <h3>
                                <Link to={`/projects/${project.slug}`}>
                                  {project.title}
                                </Link>
                              </h3>
                              <p>{project.shortText}</p>
                              <Link to={`/projects/${project.slug}`} className="learn-more-btn">
                                <Icon.ArrowRight /> Learn More
                              </Link>

                              <div className="shape">
                                <img src={Rectangle} alt="shape" />
                              </div>

                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {/* Shape Images */}
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape2 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
            <div className="shape7">
                <img src={Shape4} alt="shape" />
            </div>
            <div className="shape4">
                <img src={Shape4} alt="shape" />
            </div>
        </div>
    );
}

export default ProjectStyle1;
